// jQuery
import $ from "jquery";
window.$ = window.jQuery = $;

require("jquery-migrate");
jQuery.migrateMute = true;

require("lodash");

// React
require("react");
require("react-router");
require("history");
require("react-bootstrap");
require("nuclear-js");
require("immutable");
require("tcomb");
require("tcomb-form");
require("tcomb-validation");
require("classnames");
//require('react-loader')
require("react-conditional-component");
require("react-sidebar");
require("react-avatar-editor");
require("react-dnd");
require("react-dnd-html5-backend");
require("react-chartjs");

// i18n
if (!global.Intl) {
  global.Intl = require("intl");
}
require("intl-messageformat");
require("intl-relativeformat");
require("intl-format-cache");
require("react-intl");

// Ajax + Promises
require("axios");

// DataTables
require("datatables.net");
require("datatables.net-bs");
require("./vendors/datatables/fnReloadAjax");

// MetisMenu
require("metismenu");

//
require("jsonwebtoken");
require("jwt-decode");
require("keymirror");
require("moment");
require("easy-pie-chart");

// Local
require("./vendors/pace.min");
require("./vendors/messenger.min");
require("./vendors/messenger-theme-flat");

// Assets
import "./sass/vendors.scss";
