/* eslint-disable react/prop-types */
'use strict';

var _extends = require('babel-runtime/helpers/extends')['default'];

var _interopRequireDefault = require('babel-runtime/helpers/interop-require-default')['default'];

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _BootstrapMixin = require('./BootstrapMixin');

var _BootstrapMixin2 = _interopRequireDefault(_BootstrapMixin);

var ModalDialog = _react2['default'].createClass({
  displayName: 'ModalDialog',

  mixins: [_BootstrapMixin2['default']],

  propTypes: {
    /**
     * A Callback fired when the header closeButton or non-static backdrop is clicked.
     * @type {function}
     * @required
     */
    onHide: _react2['default'].PropTypes.func.isRequired,

    /**
     * A css class to apply to the Modal dialog DOM node.
     */
    dialogClassName: _react2['default'].PropTypes.string

  },

  getDefaultProps: function getDefaultProps() {
    return {
      bsClass: 'modal',
      closeButton: true
    };
  },

  render: function render() {
    var modalStyle = _extends({
      display: 'block'
    }, this.props.style);
    var bsClass = this.props.bsClass;
    var dialogClasses = this.getBsClassSet();

    delete dialogClasses.modal;
    dialogClasses[bsClass + '-dialog'] = true;

    return _react2['default'].createElement(
      'div',
      _extends({}, this.props, {
        title: null,
        tabIndex: '-1',
        role: 'dialog',
        style: modalStyle,
        className: _classnames2['default'](this.props.className, bsClass) }),
      _react2['default'].createElement(
        'div',
        { className: _classnames2['default'](this.props.dialogClassName, dialogClasses) },
        _react2['default'].createElement(
          'div',
          { className: bsClass + '-content', role: 'document' },
          this.props.children
        )
      )
    );
  }
});

exports['default'] = ModalDialog;
module.exports = exports['default'];